import { Modal } from "antd";
import React, { useState } from "react";
import Estimate from "../Estimate/Estimate";
import { Link } from "react-router-dom";

const About = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  return (
    <>
      <div class="container-fluid py-5">
        <div class="container py-5">
          <div class="row gx-0 mb-3 mb-lg-0">
            <div class="col-lg-6 my-lg-5 py-lg-5">
              <div
                class="about-start p-5"
                style={{ backgroundColor: "#073F6E", borderRadius: "20px" }}
              >
                <h1 class="display-5 mb-4" style={{ color: "white" }}>
                  Welcome to Your Cleaning Pros LLC
                </h1>
                <p style={{ color: "white" }}>
                  Your Cleaning Pros LLC, we strive to earn and maintain the
                  trust that our customers give us when they choose Your
                  Cleaning Pros LLC cleaning services as their cleaning
                  provider. Thank you for considering using our organization.{" "}
                </p>
                <Link
                  to="/contact"
                  class="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4"
                  style={{ backgroundColor: "white", color: "#073F6E" }}
                >
                  Contact Us
                </Link>
                {/* <a
                  href=""
                  class="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4"
                  style={{ backgroundColor: "white", color: "#073F6E" }}
                >
                  Contact Us
                </a> */}
              </div>
            </div>
            <div class="col-lg-6" style={{ minHeight: "400px" }}>
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100"
                  src="images/logo.png"
                  style={{ objectFit: "cover", borderRadius: "20px" }}
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div class="row gx-0">
            <div class="col-lg-6" style={{ minHeight: "400px" }}>
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100"
                  src="images/5.jpg"
                  style={{ objectFit: "cover", borderRadius: "20px" }}
                  alt="img"
                />
              </div>
            </div>
            <div class="col-lg-6 my-lg-5 py-lg-5">
              <div
                class="about-end p-5"
                style={{
                  backgroundColor: "#073F6E",
                  borderRadius: "20px",
                  marginTop: "30px",
                }}
              >
                <h1 class="display-5 mb-4" style={{ color: "white" }}>
                  Why Choose Us?
                </h1>
                <p style={{ color: "white" }}>
                  The face of our business - Well-trained, well-presented,
                  competent, and qualified people - experienced, skilled,
                  capable, reliable, responsible, and equipped to act ...
                  Because Quality is Necessary. Our people are our greatest
                  asset.{" "}
                </p>
                <span
                  class="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4"
                  style={{ backgroundColor: "white", color: "#073F6E" }}
                  onClick={handleClick}
                >
                  Free Estimate
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal centered open={open} footer={null} onCancel={() => setOpen(false)}>
        <Estimate />
      </Modal>
    </>
  );
};

export default About;
