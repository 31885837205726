import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";
import { FAQs } from "../../components/Faqs/Faqs";
import { dentel } from "../../utils";

const Dental = () => {
  return (
    <>
      <Helmet>
        <title>
          Professional Dental & Medical Office Cleaning in Lancaster
        </title>
        <meta
          name="description"
          content="Ensure your medical office is spotless and compliant with regulations. Your Cleaning Pros offers professional cleaning 
services that meet OSHA standards for healthcare facilities."
        />
      </Helmet>

      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">
            {"Dental / Medical Office Cleaning"}
          </h2>
        </div>
      </div>
      <div className="content">
        <h1>Customized Dental/Medical Office Cleaning Services</h1>
        <p className="service-description">
          When it comes to keeping your employees and patients healthy, good
          hygiene and a sanitary environment are of the utmost importance.
          That’s why Your Cleaning Pros customizes every medical office cleaning
          quote to meet the unique cleaning needs of your Dental/Medical office.
          We understand the strict regulations and protocols that medical
          facilities must follow, and our many years of experience in providing
          hospital-grade cleaning services have taught us how to exceed that
          standard.
        </p>
        <p>
          We model our medical office janitorial cleaning solutions after the
          guidelines set forth by industry organizations.
        </p>
        <div className="service-content">
          <h1>
            High-Quality Dental/Medical Janitorial Services Improve Operations
          </h1>
          <p>
            The highest quality commercial cleaning practices are vital to the
            operations of your doctor’s office, dental office, hospital, or
            other medical facility. Our cleaning professionals are trained to
            provide the highest quality cleaning possible. We bring you the
            cleaning expertise to keep your healthcare facility sanitary so you
            can continue to offer your patients the best care.
          </p>
          <br />
          <h2>
            Your Cleaning Pros healthcare facility cleaning services include:
          </h2>
          <ul>
            <li style={{ listStyle: "circle" }}>
              Doctor's office cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>
              Day surgery and outpatient center cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>
              Dental office cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>
              Dialysis center cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>
              Hospital cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>
              Veterinary center cleaning and disinfecting
            </li>
            <li style={{ listStyle: "circle" }}>Hospitals</li>
            <li style={{ listStyle: "circle" }}>Infusion centers</li>
            <li style={{ listStyle: "circle" }}>Surgical centers</li>
            <li style={{ listStyle: "circle" }}>Group practices</li>
            <li style={{ listStyle: "circle" }}>Dialysis centers</li>
            <li style={{ listStyle: "circle" }}>Clinical laboratories</li>
            <li style={{ listStyle: "circle" }}>
              Office-based surgery centers
            </li>
            <li style={{ listStyle: "circle" }}>Dental facilities</li>
            <li style={{ listStyle: "circle" }}>Medical offices</li>
            <li style={{ listStyle: "circle" }}>Physician offices</li>
            <li style={{ listStyle: "circle" }}>Terminal Cleaning</li>
            <li style={{ listStyle: "circle" }}>Walk-in medical centers</li>
            <li style={{ listStyle: "circle" }}>Ambulatory care providers</li>
            <li style={{ listStyle: "circle" }}>
              Outpatient surgery facilities
            </li>
            <li style={{ listStyle: "circle" }}>
              Blood transfusion and donor centers
            </li>
            <li style={{ listStyle: "circle" }}>Healthcare Centers</li>
            <li style={{ listStyle: "circle" }}>Operation / Operating rooms</li>
            <li style={{ listStyle: "circle" }}>Surgical Centers</li>
            <li style={{ listStyle: "circle" }}>Procedure Rooms</li>
            <li style={{ listStyle: "circle" }}>
              Ambulatory surgery center (ASC)
            </li>
            <li style={{ listStyle: "circle" }}>Pre-op</li>
            <li style={{ listStyle: "circle" }}>PACU</li>
          </ul>
        </div>
      </div>
      <FAQs faqs={dentel} />

      <Foot />
    </>
  );
};

export default Dental;
