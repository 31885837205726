export const service = [
    {
        question: 'What is included in your commercial cleaning services?',
        answer: 'Our commercial cleaning services include office cleaning, floor care, window cleaning, restroom sanitization, trash removal, and more. We offer customizable cleaning schedules to meet your business needs, ensuring a spotless, professional environment for your employees and customers.'
    },
    {
        question: 'How often should I schedule commercial cleaning for my business?',
        answer: ' The frequency of commercial cleaning depends on your business type and traffic. We offer daily, nightly, or weekly cleaning  shedules. If you need a more tailored plan, we can create a schedule that fits your needs, ensuring your space remains clean and hygienic'
    },
    {
        question: 'How can your commercial cleaning services help improve employee productivity?',
        answer: ' A clean workspace can enhance employee morale and reduce absenteeism due to illness. Our professional cleaning services help create a healthy, comfortable environment that boosts productivity and promotes a positive atmosphere for your staff'
    }
];

export const office = [
    {
        question: 'What does your office cleaning service include?',
        answer: 'Our office cleaning services cover everything from cleaning desks and office furniture to vacuuming carpets, dusting surfaces, sanitizing restrooms, and board rooms. We offer flexible cleaning schedules to ensure minimal disruption during business hours.'
    },
    {
        question: 'Do you offer eco-friendly cleaning options for offices?',
        answer: 'Yes, we offer eco-friendly office cleaning services. We use green cleaning products and methods to reduce environmental impact while ensuring your office is thoroughly cleaned and sanitized.'
    },
    {
        question: 'How do you ensure the security of my office during cleaning?',
        answer: 'At Your Cleaning Pros, our cleaning teams are fully bonded and insured. We conduct thorough background checks on all staff to ensure your office’s security. We also use secure access protocols and are respectful of confidential information and sensitive documents.'
    }
];

export const dentel = [
    {
        question: 'How do you ensure cleanliness and sanitation in medical offices?',
        answer: 'We follow strict sanitation protocols and use hospital-grade disinfectants to maintain a sterile environment. Our team is trained to clean medical facilities thoroughly, focusing on high-touch areas and ensuring compliance with health regulations.'
    },
    {
        question: 'How often should I schedule medical office cleaning services?',
        answer: 'Medical offices should be cleaned regularly to prevent cross-contamination and maintain patient safety. We recommend daily cleaning services, with additional deep cleaning or disinfecting if needed, especially in high-traffic areas or during cold and flu seasons.'
    },
    {
        question: 'Are your medical office cleaning services compliant with healthcare regulations?',
        answer: 'Yes, we ensure that all of our medical office cleaning services comply with OSHA, HIPAA, and other healthcare regulations. Our cleaning crew is trained to meet the specific cleanliness and sanitation standards required in medical environments.'
    }
];

export const daycare = [
    {
        question: 'Why is daycare cleaning so important?',
        answer: 'Daycare cleaning is crucial to maintaining a healthy environment for children. With kids often touching everything, it’s important to prevent the spread of germs and viruses. We use safe, non-toxic cleaning products to sanitize play areas, toys, and surfaces, ensuring the health and safety of children and staff.'
    },
    {
        question: 'Do you use non-toxic cleaning products in daycares?',
        answer: 'Yes, we exclusively use non-toxic, child-safe cleaning products in daycares. Our goal is to provide a thoroughly cleaned environment without exposing children to harsh chemicals, keeping them safe and healthy.'
    },
    {
        question: 'How often should daycare centers schedule cleaning services?',
        answer: 'Daycares should schedule cleaning services daily to maintain a clean and healthy environment. We also offer deep cleaning services on a weekly or monthly basis to ensure thorough sanitation, especially for high-touch areas.'
    }
];

export const school = [
    {
        question: 'What are your cleaning services for schools and universities?',
        answer: 'We offer customized cleaning services for schools, including classroom cleaning, restroom sanitization, high-touch surface disinfecting, and carpet care. Our cleaning methods help reduce germs and ensure a clean and safe learning environment for students and staff.'
    },
    {
        question: 'How do you handle cleaning in high-traffic school areas?',
        answer: 'Our cleaning team is experienced in managing high-traffic areas like hallways, cafeterias, and restrooms. We focus on frequent disinfecting of high-touch surfaces and use electrostatic sprayers for enhanced sanitation, especially in high-traffic zones.'
    },
    {
        question: 'Can your cleaning services help reduce the spread of illness in schools?',
        answer: 'Yes, our cleaning services are designed to help reduce the spread of illness in schools. We use hospital-grade disinfectants and special techniques like electrostatic spraying to sanitize high-touch areas, preventing the spread of flu, colds, and other contagious illnesses.'
    }
];

export const store = [
    {
        question: 'How often should I schedule cleaning for my retail store?',
        answer: 'Retail stores require frequent cleaning due to high foot traffic. We recommend daily cleaning for high-traffic stores, with deep cleaning services scheduled monthly or quarterly to maintain a clean, professional appearance and enhance the shopping experience for your customers.'
    },
    {
        question: 'What services do you provide for retail store cleaning?',
        answer: 'Our retail store cleaning services include floor care, window washing, shelf and product display cleaning, restroom sanitation, and trash removal. We tailor our services to meet your store’s specific needs and ensure a spotless environment for customers.'
    },
    {
        question: 'How can your retail cleaning services help improve customer satisfaction?',
        answer: 'A clean store creates a positive first impression for customers, enhancing their shopping experience. Regular cleaning of floors, windows, and restrooms ensures that your retail space is welcoming and hygienic, improving customer satisfaction and loyalty.'
    }
];

export const janitorial = [
    {
        question: 'What does your janitorial cleaning service include?',
        answer: 'Our janitorial cleaning services include daily cleaning tasks such as vacuuming, dusting, sanitizing restrooms, trash removal, and cleaning office spaces. We also offer specialized services like floor care, window cleaning, and supply restocking. We tailor the service based on your business’s needs for a clean, productive environment.'
    },
    {
        question: 'How often should I schedule janitorial cleaning for my office or facility?',
        answer: 'The frequency of janitorial cleaning depends on the size of your facility and the foot traffic it experiences. We offer daily, nightly, or even weekend schedules to fit your business. Regular cleaning ensures a hygienic workplace, while deep cleaning can be scheduled monthly or quarterly for more thorough care.'
    },
    {
        question: 'Do you offer flexible janitorial cleaning schedules for businesses?',
        answer: 'Yes, we offer flexible janitorial cleaning schedules to meet your business\'s unique needs. You can choose from daily, after-hours, nightly, or weekend cleaning schedules. We also provide customized cleaning plans to ensure minimal disruption to your operations.'
    }
];
