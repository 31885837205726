import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";
import { janitorial } from "../../utils";
import { FAQs } from "../../components/Faqs/Faqs";

const Janitorial = () => {
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Janitorial services Lancaster, PA| Professional Commercial cleaners
        </title>
        <meta
          name="description"
          content=" Your Cleaning Pros offers flexible janitorial cleaning services in Lancaster, PA including Day, Night and weekend options. 
Get customized cleaning plans for your business today"
        />
      </Helmet>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">{"Janitorial Cleaning Services"}</h2>
        </div>
      </div>
      <div className="content">
        <h1>JANITORIAL CLEANING SERVICES </h1>
        <p className="service-description">
          We are able to create a cleaning plan to meet your specific needs. We
          understand that certain cleaning requirements and schedules can vary
          across the multitude of industries, which is why we customize our
          cleaning plans so that we are able to continually provide the highest
          quality of Janitorial services at every facility or place of business
          in the greater Lancaster County Area.
        </p>
        <div className="service-content">
          <h2>JANITORIAL SERVICE SCHEDULE</h2>
          <p>
            ​At Your Cleaning Pros we provide both Daily and Nightly Janitorial
            services with customizable cleaning schedules to best serve our
            customer base. Our company knows that many of our customers work on
            different time schedules and have varying preferences as to when
            they would like all of the commercial cleaning services to be
            completed.
          </p>
          <p>
            In order to meet the specific needs of all of our clients across
            many cleaning industries, we have remained flexible with our service
            schedules by providing multiple options to all of our Janitorial
            Service clients. Some of the options that we currently provide
            include:
          </p>
          <ul>
            <li style={{ listStyle: "circle" }}>
              ​Daily Janitorial service schedule (Generally 9AM-5PM)
            </li>
            <li style={{ listStyle: "circle" }}>
              ​After standard business hours (5PM-10PM)
            </li>
            <li style={{ listStyle: "circle" }}>
              Nightly Janitorial service schedule (Usually from 10PM-7AM)
            </li>
            <li style={{ listStyle: "circle" }}>Weekend cleaning schedule</li>
            <li style={{ listStyle: "circle" }}>
              ​Other customized cleaning schedule
            </li>
          </ul>
          <br />
          <h2>DAY PORTER SERVICE</h2>
          <p>
            ​At Your Cleaning Pros we know that not every cleaning service can
            be done after regular hours of operation or even overnight, which is
            why we offer day porter services to companies and facilities with a
            large number of employees that need cleaning services to be provided
            to them during the day. Our cleaning company recognizes the
            challenges of our cleaning employees working directly with the
            facilities’ staff and public compared to just working alone
            overnight.
          </p>
          <p>
            We are fully aware of the importance of maintaining a high standard
            of professionalism when working directly with people while
            performing the daily cleaning tasks. In order to achieve a high
            level of professionalism in the workplace we make sure that all of
            our employees possess great customer service skills and are provided
            with properly fitted uniforms, name badges, daily cleaning plans and
            emergency contact information. We believe that with these qualities
            we will be able to provide our customers with exceptional cleaning
            services day after day.
          </p>
          <p>
            Our Day Porter Service employees have many important
            responsibilities throughout the course of a daily cleaning shift.
            Some of the daily cleaning requirements include the following:
          </p>
          <ul>
            <li style={{ listStyle: "circle" }}>
              ​Cleaning and maintaining high traffic areas
            </li>
            <li style={{ listStyle: "circle" }}>
              Cleaning bathrooms, kitchens, lunchrooms, offices and conference
              rooms
            </li>
            <li style={{ listStyle: "circle" }}>
              ​Refilling and ordering consumable supplies as needed
            </li>
            <li style={{ listStyle: "circle" }}>
              ​Removing and disposing of all trash in the facility
            </li>
            <li style={{ listStyle: "circle" }}>
              Following of daily scheduled cleaning plans
            </li>
            ​
            <li style={{ listStyle: "circle" }}>
              Communicating any issues or concerns to maintenance manager
            </li>
          </ul>
          <br />
          <h2>OUR JANITORIAL SUPPLIES AND EQUIPMENT</h2>
          <p>
            Our Janitorial service customers can have a peace of mind knowing
            that we’ll provide all of the non-consumable cleaning supplies and
            equipment at the job site on a daily basis. The employee on duty and
            cleaning inspection supervisor will take responsibility by making
            sure that all of the cleaning supplies are stocked up and the
            equipment is in good working condition. This will ensure that all of
            the cleaning tasks at hand will receive the highest standard of
            workmanship in the Janitorial and cleaning service industry.
          </p>
          <p>
            All consumable cleaning supplies like toilet paper, paper towels,
            hand soap, hand sanitizer, trash bags and urinal blocks will be the
            responsibility of the customer. The Janitor or cleaning supervisor
            may be responsible for consistently checking the supply room stock
            and submitting necessary order form information to the maintenance
            management as needed.
          </p>
        </div>
      </div>
      <FAQs faqs={janitorial} />

      <Foot />
    </>
  );
};

export default Janitorial;
