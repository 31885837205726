import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";
import { FAQs } from "../../components/Faqs/Faqs";
import { office } from "../../utils";

const Office = () => {
  return (
    <>
      <Helmet>
        <title>
          Expert Office cleaning Service in Lancaster, PA| Customized Cleaning
          Solution
        </title>
        <meta
          name="description"
          content=" Looking for reliable office cleaning in Lancaster, PA. Your Cleaning Pros offer professional cleaning services tailored to 
your business. Keep your office spotless and productive with our expert team."
        />
      </Helmet>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">{"Office Cleaning Service"}</h2>
        </div>
      </div>
      <div className="content">
        <h1>Customized Commercial Office Cleaning Services</h1>
        <p className="service-description">
          At Your Cleaning Pros, we pride ourselves on keeping our customers
          satisfied by providing expert knowledge and high-quality office
          cleaning services. Each facility, from small office spaces to
          commercial office buildings, comes with its own set of cleaning
          requirements, which is why our office cleaning services are tailored
          to each individual workspace.
        </p>
        <div className="service-content">
          <h1>A Clean Office is a Productive Office</h1>
          <p>
            Small businesses and office buildings contain many high-touch
            surfaces - desks, light switches, keyboards, phones, toilets,
            faucets, etc. - that, if neglected, can increase an employee’s
            chance of becoming ill. A clean office space allows your employees
            to stay healthy and, in turn, increases workplace productivity. A
            high-quality office cleaning is more than empty trash cans,
            sparkling windows, and vacuumed floors; it ensures that your
            commercial office cleaner is using proper methods and safety
            procedures to provide effective cleaning services to keep your
            business's office safe and productive.
          </p>
          <br />
          <h2>
            Why Choose Your Cleaning Pros as your Commercial Office Cleaning
            Company?
          </h2>
          <p>
            For more than two decades, Your Cleaning Pros has provided thousands
            of business owners with peace-of-mind by making their office
            buildings cleaner and safer. Our commercial office cleaning services
            include:
          </p>
          <ul>
            <li style={{ listStyle: "circle" }}>General office cleaning</li>
            <li style={{ listStyle: "circle" }}>High-to-low dusting</li>
            <li style={{ listStyle: "circle" }}>Hard floor care</li>
            <li style={{ listStyle: "circle" }}>Office carpet cleaning</li>
            <li style={{ listStyle: "circle" }}>
              Comprehensive office disinfection and sanitization
            </li>
          </ul>
          <br />
          <h1>OFFICE CLEANING CHECKLIST</h1>
          <p>
            Below is a list of only some of the cleaning requirements that are
            provided at many of our office cleaning locations.
          </p>
          <ul>
            <li style={{ listStyle: "circle" }}>Vacuum all carpeted areas</li>
            <li style={{ listStyle: "circle" }}>Mop all hard surface floors</li>
            <li style={{ listStyle: "circle" }}>Wipe down office desks</li>
            <li style={{ listStyle: "circle" }}>Remove all trash</li>
            <li style={{ listStyle: "circle" }}>High and low dust all areas</li>
            <li style={{ listStyle: "circle" }}>
              Clean and polish kitchen sink
            </li>
            <li style={{ listStyle: "circle" }}>Clean inside microwave</li>
            <li style={{ listStyle: "circle" }}>
              Clean all countertops and tabletops
            </li>
            <li style={{ listStyle: "circle" }}>
              Wipe all entrance glass doors
            </li>
            <li style={{ listStyle: "circle" }}>
              Dispose of all trash in dumpster
            </li>
            <li style={{ listStyle: "circle" }}>
              Disinfect and clean urinals and toilets
            </li>
            <li style={{ listStyle: "circle" }}>Clean bathroom sink</li>
            <li style={{ listStyle: "circle" }}>Refill consumable supplies</li>
            <li style={{ listStyle: "circle" }}>
              Detail clean any specific spots
            </li>
            <li style={{ listStyle: "circle" }}>Sweep hard floors</li>
            <li style={{ listStyle: "circle" }}>Clean any dirty wall spots</li>
            <li style={{ listStyle: "circle" }}>Disinfect Doorknobs</li>
            <li style={{ listStyle: "circle" }}>Lock up the office building</li>
          </ul>
        </div>
      </div>
      <FAQs faqs={office} />

      <Foot />
    </>
  );
};

export default Office;
