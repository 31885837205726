import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";
import { school } from "../../utils";
import { FAQs } from "../../components/Faqs/Faqs";

const School = () => {
  return (
    <>
      <Helmet>
        <title>
          {" "}
          School and Institutional Cleaning Services in Lancaster, PA – Keep
          Students Safe & Healthy
        </title>
        <meta
          name="description"
          content="Your Cleaning Pros provides professional cleaning and disinfection services for schools and Educational institutes in 
Lancaster, PA. Keep your classrooms, offices, and common areas spotless and safe for students and staff.
"
        />
      </Helmet>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">
            {"School and Institutional Cleaning Service"}
          </h2>
        </div>
      </div>
      <div className="content">
        <h1>
          Customized Cleaning Services for Educational Facilities and Schools
        </h1>
        <p className="service-description">
          The trained cleaning crews at Your Cleaning Pros are committed to
          providing the utmost attentiveness required to keep your students and
          staff members at your school safe and healthy.
        </p>
        <p>
          Our cleaning services for schools are customized to fit the needs of
          your learning facility. We understand the nuances of cleaning schools,
          universities, and other educational facilities, as well as the
          importance of performing comprehensive background checks on our
          janitorial service providers to ensure students’ safety.
        </p>
        <div className="service-content">
          <h1>Are Your School’s Janitorial Services Up to Par?</h1>
          <p>
            Your Cleaning Pros understands the pressure and uncertainty that has
            arisen from the pandemic, particularly when it comes to cleaning and
            disinfecting schools and educational facilities. Thorough cleaning
            and disinfecting procedures in schools matter now more than ever
            before, which is why we are offering additional tools and techniques
            to our standard school cleaning services.
          </p>
          <p>
            Tools like electrostatic disinfectant sprayers enable our cleaning
            crews to sanitize and disinfect the high-touch surfaces and high
            traffic areas throughout your educational facility. These tools can
            help reduce the spread of germs and illnesses such as the seasonal
            flu and Covid-19 in densely trafficked areas in your school. From
            primary schools to universities, Corvus Janitorial will help keep
            your classrooms clean and your students healthy.
          </p>
          <br />
          <h1>
            Commercial Cleaning Services for Schools Promote Healthy Learning
          </h1>
          <p>
            A clean, safe, and healthy learning environment is critical for your
            educational facility. Keeping your school clean and sanitary is
            essential to:
          </p>
          <br />
          <h4>Promote good health</h4>
          <p>
            A clean and sanitary school environment helps to prevent the spread
            of germs and illnesses, keeping students and staff healthy. When
            schools are not properly cleaned, harmful bacteria and viruses can
            quickly spread, leading to illnesses such as the flu, colds, and
            other infectious diseases.
          </p>
          <br />
          <h4>Enhance academic performance</h4>
          <p>
            Studies have shown that a clean and sanitary school environment can
            positively impact academic performance. When students learn in a
            clean and organized environment, they can stay focused on their
            studies, leading to improved academic performance.
          </p>
          <br />
          <h4>Create a safe learning environment</h4>
          <p>
            A clean school environment creates a safe learning environment for
            students. When schools are dirty and cluttered, it can create
            hazards such as tripping over objects or slipping on wet floors,
            which can result in injuries. A well-maintained school environment
            promotes safety and reduces the risk of accidents.
          </p>
          <br />
          <h4>Improve morale</h4>
          <p>
            A clean and sanitary school environment can also improve the morale
            of students and staff. A well-maintained environment can create a
            positive atmosphere, making students and staff feel more comfortable
            and motivated to learn and teach.
          </p>
          <br />
          <h2>School cleaning services include:</h2>
          <ul>
            <li style={{ listStyle: "circle" }}>
              Office and classroom cleaning
            </li>
            <li style={{ listStyle: "circle" }}>Common area cleaning</li>
            <li style={{ listStyle: "circle" }}>Restroom cleaning</li>
            <li style={{ listStyle: "circle" }}>Trash removal</li>
            <li style={{ listStyle: "circle" }}>High-to-low dusting</li>
            <li style={{ listStyle: "circle" }}>Hard floor care</li>
            <li style={{ listStyle: "circle" }}>Carpet cleaning</li>
            <li style={{ listStyle: "circle" }}>
              Comprehensive disinfection and sanitization
            </li>
          </ul>
        </div>
      </div>
      <FAQs faqs={school} />

      <Foot />
    </>
  );
};

export default School;
