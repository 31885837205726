import React from "react";
import "./Contact.css";
import Nav from "../../components/Nav/Nav";
import Contac from "../../components/Contac/Contac";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Contact Us | Quality Commercial cleaning Services Lancaster County
        </title>
        <meta
          name="description"
          content=" Looking for cleaning services in Lancaster, PA? Click through to get in touch with Your Cleaning Pros. We offer free 
estimates!"
        />
      </Helmet>
      <Nav active={"Contact"} />
      <Contac />
      <Foot />
    </>
  );
};

export default Contact;
