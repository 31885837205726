import React from "react";
import Nav from "../../components/Nav/Nav";
import Heading from "../../components/Heading/Heading";
import Services from "../../components/Services/Services";
import { Info } from "../../components/Info/Info";
import { Spotless } from "../../components/Spotless/Spotless";
import { Footer } from "../../components/Footer/Footer";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";
import { service } from "../../utils";
import { FAQs } from "../../components/Faqs/Faqs";

const Service = () => {
  return (
    <div style={{ background: "white" }}>
      <Helmet>
        <title>
          Commercial Cleaning Services in Lancaster, PA| Best Cleaning Company
        </title>
        <meta
          name="description"
          content=" Get top-rated commercial cleaning services in Lancaster. Trusted cleaners for offices, retail spaces, and more. Reliable, 
professional, and affordable cleaning solutions."
        />
      </Helmet>
      <Nav active={"Service"} />
      <div
        class="container-fluid bg-primary py-5 bg-hero"
        style={{ marginBottom: "-80px" }}
      >
        <div class="container py-5">
          <div class="row justify-content-start">
            <div class="col-lg-8 text-center text-lg-start">
              <h1 class="display-1 text-dark">Services</h1>
              <div class="pt-2">
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Home
                </a>
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Heading />
      <Services />
      <Info />
      <Spotless />
      <FAQs faqs={service} />
      {/* <Footer /> */}
      <Foot />
    </div>
  );
};

export default Service;
