import React from "react";
import Nav from "../../components/Nav/Nav";
import Abou from "../../components/Abou/Abou";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title> About Your Cleaning Pros LLC</title>
        <meta
          name="description"
          content=" Trusted commercial cleaning experts delivering tailored solutions with professionalism and excellence. Experience 
spotless results and unmatched reliability in every service we provide."
        />
      </Helmet>
      <Nav active={"About"} />
      <Abou />
      <Foot />
    </>
  );
};

export default About;
