import { useState, useRef, useEffect } from "react";
import { AiFillUpCircle, AiFillDownCircle } from "react-icons/ai";

export const FAQs = ({ faqs, title = "Frequently Asked Questions" }) => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "48px 16px",
    textAlign: "center",
    borderRadius: "20px",
  };

  const titleStyle = {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#111827",
    marginBottom: "32px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{title}</h2>
      <div>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const itemStyle = {
    border: "1px solid #d1d5db",
    borderRadius: "16px",
    boxShadow: isOpen
      ? "0 6px 20px rgba(0, 0, 0, 0.15)"
      : "0 2px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "16px",
    transition: "all 0.4s ease",
    background: "#fff",
    overflow: "hidden",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    background: "none",
    border: "none",
    outline: "none",
    cursor: "pointer",
    textAlign: "left",
    fontSize: "1.25rem",
    fontWeight: "600",
    color: "#1f2937",
  };

  const contentStyle = {
    maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0",
    opacity: isOpen ? 1 : 0,
    transition: "max-height 0.4s ease, opacity 0.4s ease-in-out",
    padding: isOpen ? "0 20px 20px" : "0 20px",
    color: "#374151",
  };

  return (
    <div style={itemStyle}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={buttonStyle}
        aria-expanded={isOpen}
        aria-controls={`faq-content-${question}`}
      >
        <span>{question}</span>
        <span style={{ marginLeft: "12px" }}>
          {isOpen ? (
            <AiFillUpCircle
              style={{ width: "28px", height: "28px", color: "#6b7280" }}
            />
          ) : (
            <AiFillDownCircle
              style={{ width: "28px", height: "28px", color: "#6b7280" }}
            />
          )}
        </span>
      </button>

      <div id={`faq-content-${question}`} style={contentStyle} ref={contentRef}>
        {answer}
      </div>
    </div>
  );
};
