import React from "react";
import Hero from "../../components/Hero/Hero";
import Nav from "../../components/Nav/Nav";
import Start from "../../components/Home/Start";
import About from "../../components/Home/About";
import Services from "../../components/Home/Services";
import Qoute from "../../components/Home/Qoute";
import Testimonial from "../../components/Testimonial/Testimonial";
import Action from "../../components/Home/Action";
import Foot from "../../components/Foot/Foot";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Professional Commercial Cleaners in Lancaster – Quality You Can Trust
        </title>
        <meta
          name="description"
          content="Your Cleaning Pros LLC provides quality commercial cleaning services tailored to your business needs. From spotless 
offices to welcoming workspaces, our dedicated team ensures a clean, comfortable environment."
        />
      </Helmet>
      <Nav active={"Home"} />
      <Hero />
      {/* <Start /> */}
      <About />
      <Services />
      <Qoute />
      {/* <Members /> */}
      <Testimonial />
      {/* <Action /> */}
      <Foot />
    </>
  );
};
